import FacebookIcon from '@mui/icons-material/Facebook';
import { Phone, Email, LinkedIn } from "@material-ui/icons"
import React from 'react';
import "./contact-card.css"
import Typography from '@material-ui/core/Typography';
import Hidden from "@material-ui/core/Hidden"

const ContactCard = () => {
    return (
        <div className="contact-card">
            <div className="yo-content">
                <a className="yo">
                    <Typography color="inherit" className='footer-font'>
                        Jyothis Special School and Therapy Centre
                    </Typography>
                </a>
            </div>

            <Typography color="inherit" className='footer-font'>
                Puthiyakavu P.O, Mavelikkara, Alappuzha, Kerala, India - 690101
            </Typography>
            <hr style={{ color: "white", backgroundColor: "white", width: "60%" }} />
            <br />
            <div className="footer-social">
                <a target="__blank" className="footer-social-link" href="tel:04792305400">
                    <Phone /> &nbsp;
                    <Hidden xsDown>
                        <Typography color="inherit" className='footer-font'>
                            0479-2305400 / 0479-2306020
                        </Typography>
                    </Hidden>
                </a>
                <a target="__blank" className="footer-social-link" href="mailto:jyothismts94@gmail.com">
                    <Email /> &nbsp;
                    <Hidden xsDown>
                        <Typography color="inherit" className='footer-font'>
                            jyothismts94@gmail.com
                        </Typography>
                    </Hidden>
                </a>
                <a target="__blank" className="footer-social-link" href="https://www.facebook.com/JyothisSchool">
                    <FacebookIcon />&nbsp;
                    <Hidden xsDown>

                        <Typography color="inherit" className='footer-font'>
                            https://www.fb.com/JyothisSchool
                        </Typography>
                    </Hidden>

                </a>
                <a target="__blank" className="footer-social-link" href="https://www.linkedin.com/company/jyothis">
                    <LinkedIn />&nbsp;
                    <Hidden xsDown>

                        <Typography color="inherit" className='footer-font'>
                            https://www.linkedin.com/company/jyothis
                        </Typography>
                    </Hidden>

                </a>
            </div>
        </div>
    )
}

export default ContactCard